import ThemisInput from "@/components/shared/input"
import ThemisCascadedInput from "@/components/shared/cascaded-input"
import { FIELD_TYPES } from "@/constants"
import { checkArrayEqualityIgnoreOrder } from "@/utils"

export default {
  name      : "FieldCondition",
  components: {
    ThemisInput,
    ThemisCascadedInput
  },
  data: () => ({
    localFieldCondition: null
  }),
  props: {
    pFieldCondition                   : Object,
    pFieldsToDisplay                  : Array,
    pUseCascadedInput                 : Boolean,
    pField                            : Object,
    pSelectedFormTemplateConfiguration: Object,
    pShowDeleteIcon                   : Boolean
  },
  emits: [
    "saveFieldCondition"
  ],
  computed: {
    isBooleanField() {
      return this.pField.type === FIELD_TYPES.BOOLEAN.value
    },
    optionsToDisplay() {
      return this.localFieldCondition.fieldConditionCriteria.optionsToDisplay
    }
  },
  methods: {
    onNodeSelect(selectedNodes) {
      this.localFieldCondition.fieldConditionCriteria.values = selectedNodes
    },
    removeFieldCondition() {
      this.$emit("removeCondition")
    }
  },
  watch: {
    pFieldCondition: {
      immediate: true,
      handler  : function(newValue) {
        const fieldCondition = JSON.parse(JSON.stringify(newValue))
        if (fieldCondition) {
          if (this.isBooleanField) {
            const fieldConditionCriteria  = fieldCondition.fieldConditionCriteria
            const values                  = fieldConditionCriteria.values
              .map(value => value === "true" ? "Yes" : value === "false" ? "No" : value)
            fieldConditionCriteria.values = values.length ? values.join("") : null
          }
          this.localFieldCondition = fieldCondition
        }
      }
    },
    "localFieldCondition": {
      deep   : true,
      handler: function(value) {
        if (value) {
          let values = value.fieldConditionCriteria.values
          if (this.isBooleanField) {
            values = values?.length ? [values === "Yes" ? "true" : values === "No" ? "false" : values]
              : []
          }
          const childrenIds       = value.fieldConditionCriteria.childrenIds
          const originalValues    = this.pFieldCondition.fieldConditionCriteria.values
          const originalChildren  = this.pFieldCondition.fieldConditionCriteria.childrenIds
          const isChildrenChanged = !checkArrayEqualityIgnoreOrder(childrenIds, originalChildren)
          const isValuesChanged   = !checkArrayEqualityIgnoreOrder(values, originalValues)
          if (isChildrenChanged || isValuesChanged) {
            const fieldConditionToEmit = JSON.parse(JSON.stringify(value))
            if (this.isBooleanField) {
              fieldConditionToEmit.fieldConditionCriteria.values = values
            }
            this.$emit("saveFieldCondition", fieldConditionToEmit)
          }
        }
      }
    }
  }
}