var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mt-5",attrs:{"data-cy":"5970"}},[_c('v-col',{staticClass:"col-6",attrs:{"data-cy":"5971"}},[_c('span',{ref:"text_when_condition",staticClass:"black--text body-1",attrs:{"data-cy":"5972"}},[_vm._v(_vm._s(_vm.$t('1805')))]),_c('themis-input',{ref:"themis_input_when_condition",staticClass:"mt-4",attrs:{"data-cy":"5973","p-label":_vm._f("required")(("" + (_vm.$t('1801')))),"p-label-class":"secondary--text","p-name":"1801"},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var onBlur = ref.onBlur;
var onFocus = ref.onFocus;
var validate = ref.validate;
return [(_vm.pUseCascadedInput)?_c('themis-cascaded-input',{ref:"themis_cascaded_input_when_condition",attrs:{"data-cy":"5974","p-color":"secondary","p-item-key":"name","p-item-text":"name","p-items":_vm.optionsToDisplay,"p-selected":_vm.localFieldCondition.fieldConditionCriteria.values,"p-placeholder":_vm.$t('1803'),"p-multiple":"","p-dense":""},on:{"focus":onFocus,"blur":onBlur,"selected":_vm.onNodeSelect}}):_c('v-autocomplete',{ref:"autocomplete_when_condition",attrs:{"data-cy":"5975","background-color":"white","color":"secondary","item-text":"name","item-value":"name","placeholder":_vm.$t('1803'),"multiple":!_vm.isBooleanField,"items":_vm.optionsToDisplay,"outlined":"","dense":""},on:{"focus":onFocus,"blur":onBlur},model:{value:(_vm.localFieldCondition.fieldConditionCriteria.values),callback:function ($$v) {_vm.$set(_vm.localFieldCondition.fieldConditionCriteria, "values", $$v)},expression:"localFieldCondition.fieldConditionCriteria.values"}})]}}])})],1),_c('v-col',{staticClass:"col-6",attrs:{"data-cy":"5976"}},[_c('span',{ref:"text_then_show_condition",staticClass:"black--text body-1",attrs:{"data-cy":"5977"}},[_vm._v(_vm._s(_vm.$t('1806')))]),_c('v-row',{staticClass:"mt-1",attrs:{"data-cy":"2864"}},[_c('v-col',{staticClass:"col-11",attrs:{"data-cy":"2865"}},[_c('themis-input',{ref:"themis_input_then_show_condition",attrs:{"data-cy":"5978","p-label":_vm._f("required")(("" + (_vm.$t('1802')))),"p-label-class":"secondary--text","p-name":"1802"},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var onBlur = ref.onBlur;
var onFocus = ref.onFocus;
return [_c('v-autocomplete',{ref:"autocomplete_then_show_condition",attrs:{"data-cy":"5979","background-color":"white","color":"secondary","item-text":"systemName","item-value":"id","placeholder":_vm.$t('1804'),"items":_vm.pFieldsToDisplay,"outlined":"","dense":"","multiple":""},on:{"focus":onFocus,"blur":onBlur},model:{value:(_vm.localFieldCondition.fieldConditionCriteria.childrenIds),callback:function ($$v) {_vm.$set(_vm.localFieldCondition.fieldConditionCriteria, "childrenIds", $$v)},expression:"localFieldCondition.fieldConditionCriteria.childrenIds"}})]}}])})],1),_c('v-col',{staticClass:"d-flex align-center col-1",attrs:{"data-cy":"2866"}},[(_vm.pShowDeleteIcon)?_c('v-icon',{ref:"icon_remove_condition",attrs:{"data-cy":"2091","color":"error darken 2"},on:{"click":_vm.removeFieldCondition}},[_vm._v("mdi-trash-can-outline")]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }